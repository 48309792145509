import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import validateCnpj from '../../../helpers/validateCnpj';

const schema = yup.object().shape({
  uniqueId: yup
    .string()
    .required('campo obrigatório')
    // .test('is-cnpj', 'Cnpj inválido, tente novamente', value =>
    //   validateCnpj(value),
    // ),
});

export const formOptions = {
  resolver: yupResolver(schema),
  mode: 'onSubmit',
  defaultValues: { uniqueId: '' },
  reValidateMode: 'onChange',
};
