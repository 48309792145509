import { useState } from 'react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast, Input,
} from '@chakra-ui/react';

import { publicParticipant } from '../../../services/endpoints/patEndpoints';
import MaskedInput from '../../shared/MaskedInput';
import { formOptions } from './form';
import {
  useLoginStepContext,
  STEP_TWO,
  LOGIN_STEP,
} from '../../../hooks/useLoginSteps';

import api from '../../../services/api';

const StepOne = () => {
  const toast = useToast();
  const { setLoginState } = useLoginStepContext();
  const [isLoading, setLoading] = useState(false);
  const { handleSubmit, control, errors, setError } = useForm(formOptions);

  async function onSubmit(data) {
    const uniqueId = data.uniqueId.replace(/[^0-9]/g, '');

    try {
      setLoading(true);
      const { data: participant } = await api.get(
        `${publicParticipant}/${uniqueId}`,
      );

      if (participant?.isAccepted) {
        return setLoginState({
          currentStep: LOGIN_STEP,
          uniqueId,
        });
      }

      return setLoginState({
        currentStep: STEP_TWO,
        uniqueId,
        name: participant.name,
      });
    } catch (error) {
      if (error?.response?.status === 404) {
        return setError('uniqueId', {
          type: 'invalid',
          message: 'usuário não existe',
        });
      }
      return toast({
        position: 'top-right',
        title: 'Erro de conexão.',
        description: 'Por favor, tente novamente.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Box mb={16}>
        <Text as="small" fontSize="xl" color="brand-main">
          Seja bem-vindo ao programa
        </Text>
        <Heading
          as="h1"
          lineHeight="normal"
          fontSize={{ base: '4xl', md: '5xl' }}
          color="brand-main"
        >
          Campanha de Incentivo
        </Heading>
      </Box>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <FormControl id="unique_id" mb="32px" isInvalid={errors.uniqueId}>
          <FormLabel fontWeight="bold" color="grey.200">
            Login
          </FormLabel>
          <MaskedInput
            control={control}
            name="uniqueId"
            // mask="99.999.999/9999-99"
            type="text"
            placeholder="Digite seu Login"
          />
          <FormErrorMessage>{errors.uniqueId?.message}</FormErrorMessage>
        </FormControl>
        <Flex justify="flex-end">
          <Button
            size="lg"
            maxWidth="140px"
            type="submit"
            isLoading={isLoading}
            variant="brand-brighter"
            rightIcon={<ArrowForwardIcon />}
          >
            <Text fontSize="md">Continuar</Text>
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default StepOne;
