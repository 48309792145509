import { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';

import { useLoginStepContext } from '../../../hooks/useLoginSteps';
import StepOne from '../StepOne';
import StepTwo from '../StepTwo';
import StepThree from '../StepThree';
import LoginStep from '../LoginStep';
import LoggedStep from '../LoggedStep';
import Header from '../Header';
import Footer from '../Footer';

const STEPS = {
  STEP_ONE: StepOne,
  STEP_TWO: StepTwo,
  STEP_THREE: StepThree,
  LOGIN_STEP: LoginStep,
  LOGGED_STEP: LoggedStep,
};

const Main = () => {
  const { loginState } = useLoginStepContext();
  const [step, setStep] = useState('STEP_ONE');

  useEffect(() => setStep(loginState.currentStep), [loginState]);

  const CurrentStep = STEPS[step];

  return (
    <Flex
      as="main"
      bg="white"
      width={{
        base: '95vw',
        md: '50vw',
      }}
      height={{ base: 'fit-content', md: '100%' }}
      margin={{
        base: '140px auto 40px auto',
        md: '0 0 0 50vw',
      }}
      zIndex="10"
      padding={{
        base: '20px 30px 30px',
        md: '8vh 30px 30px',
      }}
    >
      <Flex
        direction="column"
        height={{ base: '100%', md: '100%' }}
        maxWidth="450px"
        margin="0 auto"
      >
        <Header />
        <CurrentStep />
        <Footer />
      </Flex>
    </Flex>
  );
};

export default Main;
