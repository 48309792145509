import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import validateCpf from '../../../helpers/validateCpf';
import { ddMMyyyyToDate } from '../../../helpers/createDate';

export const CountryStateOptions = () => (
  <>
    <option value="AC">Acre</option>
    <option value="AL">Alagoas</option>
    <option value="AP">Amapa</option>
    <option value="AM">Amazonas</option>
    <option value="BA">Bahia</option>
    <option value="CE">Ceara</option>
    <option value="DF">Distrito Federal</option>
    <option value="ES">Espirito Santo</option>
    <option value="GO">Goiás</option>
    <option value="MA">Maranhão</option>
    <option value="MT">Mato Grosso</option>
    <option value="MS">Mato Grosso do Sul</option>
    <option value="MG">Minas Gerais</option>
    <option value="PA">Para</option>
    <option value="PB">Paraíba</option>
    <option value="PR">Paraná</option>
    <option value="PE">Pernambuco</option>
    <option value="PI">Piauí</option>
    <option value="RJ">Rio de Janeiro</option>
    <option value="RN">Rio Grande do Norte</option>
    <option value="RS">Rio Grande do Sul</option>
    <option value="RO">Rondonia</option>
    <option value="RR">Roraima</option>
    <option value="SC">Santa Catarina</option>
    <option value="SP">São Paulo</option>
    <option value="SE">Sergipe</option>
    <option value="TO">Tocantins</option>
  </>
);

export const ShirtSizeOptions = () => (
  <>
    <option value="PP">PP</option>
    <option value="P">P</option>
    <option value="M">M</option>
    <option value="G">G</option>
    <option value="XG">XG</option>
    <option value="XXG">XXG</option>
  </>
);

export const MaritalStatusOptions = () => (
  <>
    <option value="Solteiro">Solteiro</option>
    <option value="Casado">Casado</option>
    <option value="Viúvo">Viúvo</option>
    <option value="Divorciado">Divorciado</option>
  </>
);

const formDefaultValue = {
  participant: {
    uniqueId: '',
    name: '',
    fantasyName: '',
    address: {
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
    },
  },
  participantManager: {
    uniqueId: '',
    name: '',
    email: '',
    phone: '',
    birthdayDate: '',
    gender: '',
    maritalStatus: '',
    shirtSize: '',
    address: {
      street: '',
      number: '',
      neighborhood: '',
      complement: '',
      city: '',
      state: '',
    },
  },
};

const cnpjSchema = yup.object().shape({
  participant: yup.object().shape({
    address: yup.object().shape({
      street: yup.string().required('campo obrigatório'),
      number: yup.string().required('campo obrigatório'),
      neighborhood: yup.string().required('campo obrigatório'),
      city: yup.string().required('campo obrigatório'),
    }),
  }),
  participantManager: yup.object().shape({
    uniqueId: yup
      .string()
      .required('campo obrigatório')
      .test('is-cpf', 'Cpf inválido, tente novamente', value =>
        validateCpf(value),
      ),
    name: yup.string().required('campo obrigatório'),
    email: yup.string().email('email invalido').required('campo obrigatório'),
    phone: yup.string().required('campo obrigatório'),
    birthdayDate: yup
      .string()
      .required('campo obrigatório')
      .test('is-date', 'Data inválida, tente novamente', value =>
        ddMMyyyyToDate(value),
      ),
    gender: yup.string().required('campo obrigatório'),
    maritalStatus: yup.string().required('campo obrigatório'),
    shirtSize: yup.string().required('campo obrigatório'),
    address: yup.object().shape({
      street: yup.string().required('campo obrigatório'),
      number: yup.string().required('campo obrigatório'),
      neighborhood: yup.string().required('campo obrigatório'),
      city: yup.string().required('campo obrigatório'),
    }),
  }),
  termsOfUse: yup.bool().oneOf([true], 'campo obrigatório'),
  regulament: yup.bool().oneOf([true], 'campo obrigatório'),
});

export const formOptions = {
  resolver: yupResolver(cnpjSchema),
  mode: 'onSubmit',
  defaultValues: formDefaultValue,
  reValidateMode: 'onChange',
};
