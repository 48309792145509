/* eslint-disable */
import { useState } from 'react'
import { Box, Flex, Icon, Text, Center, Tooltip } from '@chakra-ui/react'
import { MdAttachMoney, MdOutlineCheck, MdClose, MdInfo } from 'react-icons/md'
import { Currency } from '../../../../helpers/currency'

import Modal from '../Modal';

// eslint-disable-next-line react/prop-types
function Result({ params, infoParticipant, kpiEquation, kpi }) {
  const [isOpen, setIsOpen] = useState(false)

  console.log("Participant: ", infoParticipant, kpiEquation, kpi)
  let finalResult = [];
  let calculeFinal = false;

  const calculeFinalResult = (finalResult) => {
    if (typeof finalResult === 'object') {
      finalResult.pop();
      finalResult = finalResult.map(it => {
        if (it === "OU") return "||";
        if (it === "E") return "&&";
        return it;
      })
      console.log("Final Result: ", finalResult)
      finalResult = finalResult.join("");

      try {
        return eval(finalResult);
      }catch (error){
        return false;
      }

    }

    return false;
  }

  return (
    <>
      {/*{isOpen && <Modal />}*/}
      <Tooltip label="Meta de Sell-out não atingida" borderRadius="8px" padding="2px 10px" placement="top-end">
        <Box
          color="white"
          bg="linear-gradient(.31deg, #212121 0%, #3b3b3b 100%)"
          className="card"
          p="0"
          pos="relative"
          onClick={() => setIsOpen(!isOpen)}
        >
          <Text p="1.5rem 1rem 1rem">{ kpi?.name }</Text>
          <Flex p="1.5rem 1rem 1rem" flexDir="column">
            <Center w="full" justifyContent="space-between">

              <Text>
                Premiação parcial por
                {' '}
                <Text as="u">metas atingidas</Text>
              </Text>
              <Icon as={MdInfo} />
            </Center>
            <Box w="full">
              {
                infoParticipant &&
                infoParticipant?.participant?.results.map((result) => {
                  if (kpiEquation.find(equation => equation.id === result.kpiequation_id)) {
                    const kpi = kpiEquation.find(equation => equation.id === result.kpiequation_id)

                    finalResult.push(result?.result);
                    finalResult.push(kpi.prefix);

                  return (
                    <Flex w="full" m="1rem 0" p=".3rem" borderRadius="10px" border="1px solid #ccc">
                      <Center w={12} bg="blackAlpha.500" borderRadius="5px">
                        {
                          result?.result === "true" ? <Icon as={MdOutlineCheck} /> : <Icon as={MdClose} />
                        }
                      </Center>
                      <Center flexDir="column" alignItems="flex-start" w="60%" ml={4}>
                        <Text as="b">{ kpi?.description }</Text>
                        <Text as="small">{ kpi.equation }</Text>
                        <Text as="small" color="gray.500">{ result?.kpiequation?.equation }</Text>
                      </Center>
                      {/*<Text>{Currency(59801.89)}</Text>*/}
                    </Flex>
                  )
                }})
              }
            </Box>
          </Flex>
          <Flex
            pos="absolute"
            bottom="0"
            w="full"
            bg="blackAlpha.800"
            borderRadius="0 0 10px 10px"
            p="1rem 2rem"
            alignItems="center"
            justifyContent="space-between"
          >
            <Center flexDir="column">
              <Text as="b">KPI Atingindo: </Text>
            </Center>
            <div>
              { finalResult.length > 0 && calculeFinalResult(finalResult) ?
                <div>
                  <Icon as={MdOutlineCheck} />
                  <Text as="b" m="0 5px"> SIM</Text>
                </div>
                :
                <div>
                  <Icon as={MdClose} />
                  <Text as="b" m="0 5px"> NÃO</Text>
                </div>
              }
            </div>


            {/*<Text whiteSpace="nowrap">*/}
            {/*  <Text as="sup" fontSize="14px" mr=".2rem" top="-.65rem">{Currency(89702.84).slice(0,2)}</Text>*/}
            {/*  <Text as="span" fontSize="30px">{Currency(89702.84).slice(3,9)}</Text>*/}
            {/*  <Text as="sup" fontSize="14px" ml=".2rem" top="-.65rem">{Currency(89702.84).slice(9,12)}</Text>*/}
            {/*</Text>*/}
          </Flex>
        </Box>
      </Tooltip>
    </>
  );
}

export default Result;
