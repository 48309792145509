/* eslint-disable */
import {useEffect, useState} from "react";
import {
  Flex,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
} from '@chakra-ui/react';
import uuid from 'react-uuid';
import Template from '../../components/shared/Template';
import EmptyStep from '../../components/Performance/EmptyStep';
import Steps from '../../components/Performance/Steps';
import api from "../../services/api";

const Performance = () => {
  const userName = JSON.parse(sessionStorage.getItem('@PAT:username')).name
  const uniqueId = JSON.parse(sessionStorage.getItem('@PAT:username')).username
  const now = new Date()

  const token = sessionStorage.getItem('@PAT:token');

  const [infoParticipant, setInfoParticipant] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [subordinates, setSubordinates] = useState([]);
  const [activeSubordinate, setActiveSubordinate] = useState(null);

  const handleInfoParticipant = async (id) => {
    console.log("ID", id)
    if (!id) return;
    api.get(`/public/participant/${id}`)
      .then((response) => {
        setInfoParticipant(response.data);
      })
      .catch((error) => {
        console.log("Error", error)
      })
  }

  const handleFindMySubordinates = async () => {
    api.get(`/client/participant/subordinates`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => {
        setSubordinates(response.data);
      })
      .catch((error) => {
        console.log("Error", error)
      })
  }

  console.log("InfoParticipant", infoParticipant)

  useEffect(() => {
    handleInfoParticipant(uniqueId);
  }, [uniqueId]);

  useEffect(() => {
    handleFindMySubordinates();
  }, []);

  useEffect(() => {
    handleInfoParticipant(activeSubordinate);
  }, [activeSubordinate]);

  if (infoParticipant.participant <= 0) return null;
  return (
    <Template>
      <Box as={Tabs} variant="unstyled" w="full" h="full" index={activeStep} onChange={setActiveStep}>
        {subordinates &&
          (
            <div style={{marginBottom: 10, padding: "0 10px"}}>
              <p>Filtrar por</p>
              <Select placeholder="Escolha um dos subordinados" variant='filled' onChange={(el) => setActiveSubordinate(el.target.value)}>
                {
                  subordinates && subordinates.map((subordinate) => <option key={subordinate.id} value={subordinate.unique_id}>{subordinate.fantasy_name}</option>)
                }
              </Select>
            </div>
          )}

        <Flex
          height="100px"
          bg="linear-gradient(.31deg, #212121 0%, #3b3b3b 100%)"
          boxShadow="0 5px 13px rgba(69,69,69,.5)"
          align="flex-start"
          justify="space-between"
          flexDir="column"
          pl={8}
          pr={8}
          pos="relative"
          zIndex="1000"
        >

          <Box color="white" pt={3} w="full">
            {userName}
            , acompanhe sua
            {' '}
            <b>
              Performance na Campanha
              {` ${infoParticipant.participant?.campaign?.name}`}
            </b>
            <Box float="right">
              { now.toLocaleDateString('pt-BR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }
            </Box>
          </Box>
          <TabList height={12}>
            {infoParticipant.participant?.campaign?.campaignSteps.map((el) => {
              return (
                <Tab
                  key={uuid()}
                  color="grey.200"
                  border="none"
                  fontWeight="bold"
                  _selected={{ color: 'white', borderBottom: '3px solid', paddingBottom: 'calc(.5rem - 3px)' }}
                >
                  {el.name}
                </Tab>
              )
            })}
          </TabList>
        </Flex>
        <TabPanels h="calc(100% - 100px)">
          {infoParticipant.participant?.campaign?.campaignSteps.map((el, index) => {
            return (
              <TabPanel key={uuid()} h="full" overflowY={el.info ? 'scroll' : 'hidden'}>
                <>{activeStep === index ? ( <Steps props={el} infoParticipant={infoParticipant} kpi={el.kpi} active={activeStep === index} activeStep={activeStep} index={index} /> ) : ( <EmptyStep /> )}</>
              </TabPanel>
            )
          })}
        </TabPanels>
      </Box>
    </Template>
  );
};

export default Performance;
