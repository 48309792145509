import { useState } from 'react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  useToast,
} from '@chakra-ui/react';

import { useAuth } from '../../../hooks/useAuth';
import { publicUser } from '../../../services/endpoints/patEndpoints';
import { useLoginStepContext } from '../../../hooks/useLoginSteps';
import { formOptions } from './form';
import api from '../../../services/api';

const StepOne = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { loginState } = useLoginStepContext();
  const { signIn } = useAuth();
  const { handleSubmit, register, errors } = useForm(formOptions);

  async function onSubmit(data) {
    const userData = {
      uniqueId: loginState.uniqueId,
      username: loginState.uniqueId,
      password: data.password,
    };

    try {
      setIsLoading(true);
      await api.post(publicUser, userData);
      return signIn({ username: loginState.uniqueId, password: data.password });
    } catch (e) {
      return toast({
        position: 'top-right',
        title: 'Erro de conexão.',
        description: 'Por favor, tente novamente.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Box mb={8}>
        <Heading as="h1" fontSize="2xl" color="brand-main">
          Cadastre sua senha
        </Heading>
      </Box>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <FormControl id="unique_id" mb="32px" isInvalid={errors.password}>
          <FormLabel fontWeight="bold" color="grey.200">
            Senha
          </FormLabel>
          <Input
            ref={register}
            name="password"
            type="password"
            height="38px"
            borderRadius="md"
            borderColor="#ddddee"
            _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
          />
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>
        <FormControl
          id="unique_id"
          mb="32px"
          isInvalid={errors.passwordConfirmation}
        >
          <FormLabel fontWeight="bold" color="grey.200">
            Confirmar senha
          </FormLabel>
          <Input
            ref={register}
            name="passwordConfirmation"
            type="password"
            height="38px"
            borderRadius="md"
            borderColor="#ddddee"
            _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
          />
          <FormErrorMessage>
            {errors.passwordConfirmation?.message}
          </FormErrorMessage>
        </FormControl>
        <Flex justify="flex-end">
          <Button
            size="lg"
            type="submit"
            width="100%"
            borderRadius="md"
            isLoading={isLoading}
            variant="brand-brighter"
            rightIcon={<ArrowForwardIcon />}
          >
            <Text fontSize="md">Continuar</Text>
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default StepOne;
