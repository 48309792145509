import { useState } from 'react'
import { Flex, Center, Box, Text, Icon, Checkbox, Button } from '@chakra-ui/react'
import { MdOutlineCheck, MdClose, MdAttachMoney } from 'react-icons/md'

import { Currency } from '../../../../helpers/currency';

function Modal() {
  const [disabled, setDisabled] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  
  return (
    <Flex
      position="absolute"
      top="0"
      left="0"
      w="full"
      h="full"
      zIndex="2000"
      bg="rgba(0,0,0,.31)"
      alignItems="center"
      justifyContent="center"
      display={!isOpen ? 'flex' : 'none'}
      onClick={() => setIsOpen(!isOpen)}
    >
      <Center
        w="30%"
        h="80%"
        maxW="25rem"
        maxH="35rem"
        borderRadius="10px"
        bg="linear-gradient(.31deg, #212121 0%, #3b3b3b 100%)"
        color="white"
        pos="relative"
      >
        <Flex p="1rem" flexDir="column" alignSelf="flex-start" w="full">
          <Center w="full" justifyContent="space-between" pb={3} borderBottom="1px solid #222">
            <Text>
              Premiação final na 
            </Text>
          </Center>
          <Box w="full" pt={1}>
            <Text as="small">
              Metas atingidas
            </Text>
            <Flex w="full" m=".4rem 0" p=".3rem" borderRadius="10px" border="1px solid #ccc">
              <Center w={12} bg="blackAlpha.500" borderRadius="5px">
                <Icon as={MdOutlineCheck} />
              </Center>
              <Center flexDir="column" alignItems="flex-start" w="60%" ml={4}>
                <Text as="b">SELL-IN</Text>
                <Text as="small" color="gray.500">1% do Sell-In</Text>
              </Center>
              <Text>{Currency(59801.89)}</Text>
            </Flex>
            <Flex w="full" m=".4rem 0" p=".3rem" borderRadius="10px" border="1px solid #ccc">
              <Center w={12} bg="blackAlpha.500" borderRadius="5px">
                <Icon as={MdOutlineCheck} />
              </Center>
              <Center flexDir="column" alignItems="flex-start" w="60%" ml={4}>
                <Text as="b">SELL-GÔNDOLA</Text>
                <Text as="small" color="gray.500">0,5% do Sell-In</Text>
              </Center>
              <Text>{Currency(29900.95)}</Text>
            </Flex>
          </Box>

          <Box w="full">
            <Text as="small">
              Metas não atingidas
            </Text>
            <Flex w="full" m=".4rem 0" p=".3rem" borderRadius="10px" border="1px solid #ccc" opacity=".5">
              <Center w={12} bg="blackAlpha.500" borderRadius="5px">
                <Icon as={MdClose} />
              </Center>
              <Center flexDir="column" alignItems="flex-start" w="60%" ml={4}>
                <Text as="b">SELL-OUT</Text>
                <Text as="small" color="gray.500">2% do Sell-In</Text>
              </Center>
              <Text>{Currency(119603.78)}</Text>
            </Flex>
            <Flex w="full" m=".4rem 0" p=".3rem" borderRadius="10px" border="1px solid #ccc" opacity=".5">
              <Center w={12} bg="blackAlpha.500" borderRadius="5px">
                <Icon as={MdClose} />
              </Center>
              <Center flexDir="column" alignItems="flex-start" w="60%" ml={4}>
                <Text as="b">MSL CHECKOUT</Text>
                <Text as="small" color="gray.500">0,5% do Sell-In</Text>
              </Center>
              <Text>{Currency(29900.95)}</Text>
            </Flex>
          </Box>
        </Flex>
        <Box 
          pos="absolute" 
          bottom="0" 
          w="full"
          h="11rem"
          bg="white"
          color="blackAlpha.900" 
          borderRadius="10px" 
          px={5}
        >
          <Flex
            borderBottom="1px solid #ddddee"
            alignItems="center"
            justifyContent="space-between"
            py={2}
          >
            <Icon as={MdAttachMoney} />
            <Center flexDir="column">
              <Text as="b">Total Parcial</Text>
              <Text as="small" color="gray.500">1,5% do Sell-In</Text>
            </Center>
            <Text whiteSpace="nowrap">
              <Text as="sup" fontSize="14px" mr=".2rem" top="-.65rem">{Currency(89702.84).slice(0,2)}</Text>
              <Text as="span" fontSize="30px">{Currency(89702.84).slice(3,9)}</Text>
              <Text as="sup" fontSize="14px" ml=".2rem" top="-.65rem">{Currency(89702.84).slice(9,12)}</Text>
            </Text>
          </Flex>
          <Flex py={2}>
            <Checkbox onChange={() => setDisabled(!disabled)} />
            <Text fontSize="12px" ml={3}>Estou ciente que tenho direito aos valores de premiação indicados acima no acompanhamento desta Etapa</Text>
          </Flex>
          <Flex py={2}>
            <Button disabled={disabled} colorScheme="blue" w="full" borderRadius="30px">Continuar</Button>
          </Flex>
        </Box>
      </Center>
    </Flex>
  );
}

export default Modal;