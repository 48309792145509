
import { Flex, Box, Center } from '@chakra-ui/react';
import { Link, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

function Subnav({ props }) {
  const info = props;
  return (
    <Box
      as="nav"
      position="fixed"
      zIndex="1000"
      bottom="0"
      bg="linear-gradient(.31deg, #101010 0%, #444 100%)"
      height={{ base: 14, md: '100vh' }}
      width={{
        base: '100vw',
        md: '12rem',
      }}
      marginLeft="7.5rem"
    >
      <Flex direction="column" height="100%">
        <Center
          height={14}
          borderBottom="1px solid #666"
          justifyContent="flex-start"
          paddingLeft={3}
        >
          {info.title}
        </Center>
        {info.sub.map(item => {
          return (
            <Center
              key={uuid()}
              height={14}
              justifyContent="flex-start"
              paddingLeft={3}
            >
              <Link as={NavLink} to={item.href}>
                {item.title}
              </Link>
            </Center>
          );
        })}
      </Flex>
    </Box>
  );
}

Subnav.propTypes = {
  props: PropTypes.shape({
    icon: PropTypes.element,
    sub: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        href: PropTypes.string,
      }),
    ),
    title: PropTypes.string,
    href: PropTypes.string,
  }).isRequired,
};

export default Subnav;
