/* eslint-disable */
import {AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Button, Table, Thead, Tbody, Tr, Th, Td,} from "@chakra-ui/react";
import PropTypes from "prop-types";
import api from "../../services/api";
import {useEffect, useState} from "react";

const ModalPerformanceSubordinados = ({visible, setVisible, data, kpi}) => {

	console.log(data)

	useEffect(() => {
		// handleInfoParticipant();
	});

	return (
		<AlertDialog
			isOpen={visible}
			onClose={setVisible}
			size="3xl"
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						Métricas de Performance dos Subordinados
						{ kpi && kpi[0].name ? ` - ${kpi[0].name}` : null }
					</AlertDialogHeader>

					<AlertDialogBody>
						<table style={{
							width: '100%',
							borderCollapse: 'collapse',
							textAlign: 'left',
						}}>
							<thead>
							<tr>
								<th style={{textAlign: "left"}}>Nome</th>
								<th>Documento</th>
								<th>Meta Alcançada</th>
							</tr>
							</thead>

							<tbody>
							{data && data.length > 0 && data.map((item) => (
									<tr key={item.participant_id} style={{ height: 40}}>
										<td>{item.name}</td>
										<td>{item.document}</td>
										<td>{item.goalAchieved ? "Sim" : "Não"}</td>
									</tr>
								)
							)}
							</tbody>

						</table>
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button onClick={setVisible}>
							Fechar
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
}

ModalPerformanceSubordinados.propTypes = {
	visible: PropTypes.bool,
	setVisible: PropTypes.func,
	data: PropTypes.array,
	kpiId: PropTypes.string,
	participantId: PropTypes.string,
};

export default ModalPerformanceSubordinados;
