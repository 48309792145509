import { Box } from '@chakra-ui/react';

import { LoginStepProvider } from '../../hooks/useLoginSteps';
import Sidebar from '../../components/login/Sidebar';
import Main from '../../components/login/Main';

const Login = () => {
  return (
    <LoginStepProvider>
      <Box
        height="100vh"
        mw="100%"
        display="flex"
        backgroundColor="grey.300"
        flexDirection="row"
      >
        <Sidebar />
        <Main />
      </Box>
    </LoginStepProvider>
  );
};

export default Login;
