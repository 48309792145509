import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Heading,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Divider,
  Input,
  Grid,
  Checkbox,
  Stack,
  useToast,
} from '@chakra-ui/react';

import MaskedInput from '../../shared/MaskedInput';
import { publicParticipant } from '../../../services/endpoints/patEndpoints';
import { useLoginStepContext, STEP_THREE } from '../../../hooks/useLoginSteps';
import { ddMMyyyyToDate } from '../../../helpers/createDate';
import api from '../../../services/api';

import {
  formOptions,
  CountryStateOptions,
  ShirtSizeOptions,
  MaritalStatusOptions,
} from './form';

const StepTwo = () => {
  const toast = useToast();
  const [isLoading, setLoading] = useState(false);
  const { loginState, setLoginState } = useLoginStepContext();
  const { handleSubmit, control, errors, register, setValue } = useForm(
    formOptions,
  );

  useEffect(() => {
    setValue('participant.name', loginState.name);
    setValue('participant.uniqueId', loginState.uniqueId);
  }, [loginState, setValue]);

  async function onSubmit(data) {
    const updateData = {
      ...data.participant,
      name: loginState.name,
      uniqueId: loginState.uniqueId,
      participantManager: {
        ...data.participantManager,
        birthday_date: ddMMyyyyToDate(data.participantManager.birthdayDate),
        unique_id: data.participantManager.uniqueId.replace(/[^0-9]/g, ''),
        marital_status: data.participantManager.maritalStatus,
      },
      acceptedDate: new Date(),
    };

    try {
      setLoading(true);
      await api.put(publicParticipant, updateData);
      return setLoginState({ currentStep: STEP_THREE });
    } catch (e) {
      return toast({
        position: 'top-right',
        title: 'Erro de conexão.',
        description: 'Por favor, tente novamente.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Heading as="h1" fontSize="5xl" mb={2} color="brand-main">
        Cadastre-se
      </Heading>
      <Box>
        <Text as="small" fontSize="md" noOfLines={2} color="brand-brightest">
          Confirme seus dados a seguir e garanta sua participação no programa
          Campanha de Incentivo
        </Text>
      </Box>
      <Divider marginY="45px" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl
          id="participant.uniqueId"
          mb={6}
          isInvalid={errors.participant?.uniqueId}
        >
          <FormLabel fontWeight="bold" color="grey.200">
            Login
          </FormLabel>
          <MaskedInput
            control={control}
            variant="filled"
            name="participant.uniqueId"
            isDisabled
            mask="99.999.999/9999-99"
            type="text"
          />
          <FormErrorMessage>
            {errors.participant?.uniqueId?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          id="participant.name"
          mb={6}
          isInvalid={errors.participant?.name}
        >
          <FormLabel fontWeight="bold" color="grey.200">
            Razão Social
          </FormLabel>
          <Input
            control={control}
            variant="filled"
            type="text"
            height="38px"
            ref={register}
            name="participant.name"
            isDisabled
            borderRadius="md"
            borderColor="#ddddee"
            _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
          />
          <FormErrorMessage>
            {errors.participant?.name?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          id="participant.fantasyName"
          mb={6}
          isInvalid={errors.participant?.fantasyName}
        >
          <FormLabel fontWeight="bold" color="grey.200">
            Nome Fantasia
          </FormLabel>
          <Input
            type="text"
            height="38px"
            ref={register}
            name="participant.fantasyName"
            borderRadius="md"
            borderColor="#ddddee"
            _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
          />
          <FormErrorMessage>
            {errors.participant?.fantasyName?.message}
          </FormErrorMessage>
        </FormControl>
        <Grid
          templateColumns={{
            base: 'autofill',
            md: '2fr 1fr',
          }}
          gap={{
            base: 0,
            md: 7,
          }}
        >
          <FormControl
            id="participant.address.street"
            mb={6}
            isInvalid={errors.participant?.address?.street}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Endereço
            </FormLabel>
            <Input
              type="text"
              height="38px"
              ref={register}
              name="participant.address.street"
              borderRadius="md"
              borderColor="#ddddee"
              _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
            />
            <FormErrorMessage>
              {errors.participant?.address?.street?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="participant.address.number"
            mb={6}
            isInvalid={errors.participant?.address?.number}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Número
            </FormLabel>
            <Input
              type="text"
              height="38px"
              ref={register}
              name="participant.address.number"
              borderRadius="md"
              borderColor="#ddddee"
              _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
            />
            <FormErrorMessage>
              {errors.participant?.address?.number?.message}
            </FormErrorMessage>
          </FormControl>
        </Grid>
        <Grid
          templateColumns={{
            base: 'autofill',
            md: '1fr 1fr',
          }}
          gap={{
            base: 0,
            md: 7,
          }}
        >
          <FormControl
            id="participant.address.complement"
            mb={6}
            isInvalid={errors.participant?.address?.complement}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Complemento
            </FormLabel>
            <Input
              type="text"
              height="38px"
              ref={register}
              name="participant.address.complement"
              borderRadius="md"
              borderColor="#ddddee"
              _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
            />
            <FormErrorMessage>
              {errors.participant?.address?.complement?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="participant.address.neighborhood"
            mb={6}
            isInvalid={errors.participant?.address?.neighborhood}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Bairro
            </FormLabel>
            <Input
              type="text"
              height="38px"
              ref={register}
              name="participant.address.neighborhood"
              borderRadius="md"
              borderColor="#ddddee"
              _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
            />
            <FormErrorMessage>
              {errors.participant?.address?.neighborhood?.message}
            </FormErrorMessage>
          </FormControl>
        </Grid>
        <Grid
          templateColumns={{
            base: 'autofill',
            md: '1fr 1fr',
          }}
          gap={{
            base: 0,
            md: 7,
          }}
        >
          <FormControl
            id="participant.address.city"
            mb={6}
            isInvalid={errors.participant?.address?.city}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Cidade
            </FormLabel>
            <Input
              type="text"
              height="38px"
              ref={register}
              name="participant.address.city"
              borderRadius="md"
              borderColor="#ddddee"
              _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
            />
            <FormErrorMessage>
              {errors.participant?.address?.city?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="participant.address.state"
            mb={6}
            isInvalid={errors.participant?.address?.state}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Estado
            </FormLabel>
            <Select
              placeholder="Selecione o estado"
              borderColor="#ddddee"
              ref={register}
              name="participant.address.state"
              _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
            >
              <CountryStateOptions />
            </Select>
            <FormErrorMessage>
              {errors.participant?.address?.state?.message}
            </FormErrorMessage>
          </FormControl>
        </Grid>
        <Divider marginY="45px" />
        <Heading as="h2" fontSize="2xl" mb={2} color="brand-brightest">
          Suas Informações
        </Heading>
        <Grid
          templateColumns={{
            base: 'autofill',
            md: '2fr 1fr',
          }}
          gap={{
            base: 0,
            md: 7,
          }}
        >
          <FormControl
            id="participantManager.name"
            mb={6}
            isInvalid={errors.participantManager?.name}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Nome
            </FormLabel>
            <Input
              type="text"
              height="38px"
              ref={register}
              name="participantManager.name"
              borderRadius="md"
              borderColor="#ddddee"
              _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
            />
            <FormErrorMessage>
              {errors.participantManager?.name?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="participantManager.uniqueId"
            mb={6}
            isInvalid={errors.participantManager?.uniqueId}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Cpf
            </FormLabel>
            <MaskedInput
              control={control}
              name="participantManager.uniqueId"
              mask="999.999.999-99"
              type="text"
            />
            <FormErrorMessage>
              {errors.participantManager?.uniqueId?.message}
            </FormErrorMessage>
          </FormControl>
        </Grid>
        <Grid
          templateColumns={{
            base: 'autofill',
            md: '1fr 1fr',
          }}
          gap={{
            base: 0,
            md: 7,
          }}
        >
          <FormControl
            id="participantManager.email"
            mb={6}
            isInvalid={errors.participantManager?.email}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              E-mail
            </FormLabel>
            <Input
              type="text"
              height="38px"
              ref={register}
              name="participantManager.email"
              borderRadius="md"
              borderColor="#ddddee"
              _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
            />
            <FormErrorMessage>
              {errors.participantManager?.email?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="participantManager.phone"
            mb={6}
            isInvalid={errors.participantManager?.phone}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Telefone
            </FormLabel>
            <Input
              type="text"
              height="38px"
              ref={register}
              name="participantManager.phone"
              borderRadius="md"
              borderColor="#ddddee"
              _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
            />
            <FormErrorMessage>
              {errors.participantManager?.phone?.message}
            </FormErrorMessage>
          </FormControl>
        </Grid>
        <Grid
          templateColumns={{
            base: 'autofill',
            md: '2fr 1fr',
          }}
          gap={{
            base: 0,
            md: 7,
          }}
        >
          <FormControl
            id="participantManager.address.street"
            mb={6}
            isInvalid={errors.participantManager?.address?.street}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Endereço
            </FormLabel>
            <Input
              type="text"
              height="38px"
              ref={register}
              name="participantManager.address.street"
              borderRadius="md"
              borderColor="#ddddee"
              _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
            />
            <FormErrorMessage>
              {errors.participantManager?.address?.street?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="participantManager.address.number"
            mb={6}
            isInvalid={errors.participantManager?.address?.number}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Número
            </FormLabel>
            <Input
              type="text"
              height="38px"
              ref={register}
              name="participantManager.address.number"
              borderRadius="md"
              borderColor="#ddddee"
              _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
            />
            <FormErrorMessage>
              {errors.participantManager?.address?.number?.message}
            </FormErrorMessage>
          </FormControl>
        </Grid>
        <Grid
          templateColumns={{
            base: 'autofill',
            md: '1fr 1fr',
          }}
          gap={{
            base: 0,
            md: 7,
          }}
        >
          <FormControl
            id="participantManager.address.complement"
            mb={6}
            isInvalid={errors.participantManager?.address?.complement}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Complemento
            </FormLabel>
            <Input
              type="text"
              height="38px"
              ref={register}
              borderRadius="md"
              name="participantManager.address.complement"
              borderColor="#ddddee"
              _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
            />
            <FormErrorMessage>
              {errors.participantManager?.address?.complement?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="participantManager.address.neighborhood"
            mb={6}
            isInvalid={errors.participantManager?.address?.neighborhood}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Bairro
            </FormLabel>
            <Input
              type="text"
              height="38px"
              ref={register}
              name="participantManager.address.neighborhood"
              borderRadius="md"
              borderColor="#ddddee"
              _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
            />
            <FormErrorMessage>
              {errors.participantManager?.address?.neighborhood?.message}
            </FormErrorMessage>
          </FormControl>
        </Grid>
        <Grid
          templateColumns={{
            base: 'autofill',
            md: '1fr 1fr',
          }}
          gap={{
            base: 0,
            md: 7,
          }}
        >
          <FormControl
            id="participantManager.address.city"
            mb={6}
            isInvalid={errors.participantManager?.address?.city}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Cidade
            </FormLabel>
            <Input
              type="text"
              height="38px"
              ref={register}
              name="participantManager.address.city"
              borderRadius="md"
              borderColor="#ddddee"
              _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
            />
            <FormErrorMessage>
              {errors.participantManager?.address?.city?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="participantManager.address.state"
            mb={6}
            isInvalid={errors.cnpj}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Estado
            </FormLabel>
            <Select
              placeholder="Selecione o estado"
              borderColor="#ddddee"
              ref={register}
              name="participantManager.address.state"
              _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
            >
              <CountryStateOptions />
            </Select>
            <FormErrorMessage>
              {errors.participantManager?.address?.state?.message}
            </FormErrorMessage>
          </FormControl>
        </Grid>
        <Grid
          templateColumns={{
            base: 'autofill',
            md: '1fr 1fr',
          }}
          gap={{
            base: 0,
            md: 7,
          }}
        >
          <FormControl
            id="participantManager.birthdayDate"
            mb={6}
            isInvalid={errors.participantManager?.birthdayDate}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Data de Nascimento
            </FormLabel>
            <MaskedInput
              control={control}
              name="participantManager.birthdayDate"
              mask="99/99/9999"
              type="text"
            />
            <FormErrorMessage>
              {errors.participantManager?.birthdayDate?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="participantManager.gender"
            mb={6}
            isInvalid={errors?.participantManager?.gender}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Gênero
            </FormLabel>
            <Select
              placeholder="Selecione seu Gênero"
              borderColor="#ddddee"
              ref={register}
              name="participantManager.gender"
              _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
            >
              <option value="MA">Masculino</option>
              <option value="FE">Feminino</option>
              <option value="OU">Outro</option>
            </Select>
            <FormErrorMessage>
              {errors.participantManager?.gender?.message}
            </FormErrorMessage>
          </FormControl>
        </Grid>
        <Grid
          templateColumns={{
            base: 'autofill',
            md: '1fr 1fr',
          }}
          gap={{
            base: 0,
            md: 7,
          }}
        >
          <FormControl
            id="participantManager.maritalStatus"
            mb={6}
            isInvalid={errors.participantManager?.maritalStatus}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Estado Civil
            </FormLabel>
            <Select
              placeholder="Selecione o Estado Civil"
              borderColor="#ddddee"
              ref={register}
              name="participantManager.maritalStatus"
              _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
            >
              <MaritalStatusOptions />
            </Select>
            <FormErrorMessage>
              {errors.participantManager?.maritalStatus?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="participantManager.shirtSize"
            mb={6}
            isInvalid={errors.participantManager?.shirtSize}
          >
            <FormLabel fontWeight="bold" color="grey.200">
              Tamanho de Camiseta
            </FormLabel>
            <Select
              placeholder="Selecione o tamanho"
              borderColor="#ddddee"
              ref={register}
              name="participantManager.shirtSize"
              _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
            >
              <ShirtSizeOptions />
            </Select>
            <FormErrorMessage>
              {errors.participantManager?.shirtSize?.message}
            </FormErrorMessage>
          </FormControl>
        </Grid>
        <Stack flexDir="column" marginTop={5} spacing={10}>
          <FormControl id="termsOfUse" isInvalid={errors.termsOfUse}>
            <Checkbox
              colorScheme="green"
              isInvalid={errors.termsOfUse}
              ref={register}
              name="termsOfUse"
            >
              Concordo em receber comunicações do programa através dos diversos
              canais
            </Checkbox>
            <FormErrorMessage>{errors.termsOfUse?.message}</FormErrorMessage>
          </FormControl>
          <FormControl id="regulament" isInvalid={errors.regulament}>
            <Checkbox
              colorScheme="green"
              isInvalid={errors.regulament}
              name="regulament"
              ref={register}
            >
              Li e Aceito o regulamento
            </Checkbox>
            <FormErrorMessage>{errors.regulament?.message}</FormErrorMessage>
          </FormControl>
        </Stack>
        <Button
          size="lg"
          type="submit"
          isLoading={isLoading}
          marginTop={10}
          width="100%"
          variant="brand-brighter"
          rightIcon={<ArrowForwardIcon />}
        >
          <Text fontSize="md">Continuar</Text>
        </Button>
      </form>
    </>
  );
};

export default StepTwo;
