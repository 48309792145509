import { Image, Box, Center, Heading, Text } from '@chakra-ui/react'

function EmptyStep() {
  return ( 
    <Box h="full">
      <Center flexDir="column" h="full">
        <Image src="/images/dashboard.svg" />
        <Heading as="h3" size="lg">Ainda não temos parciais dessa etapa</Heading>
        <Text color="grey.200" pt={3}>Volte em breve para conferir seu progresso no programa</Text>
      </Center>
    </Box>
  )
}

export default EmptyStep;