import Template from '../../components/shared/Template';
import {Center, Container, Flex, Heading} from "@chakra-ui/react";
import {informationModals} from "../../components/login/LoggedStep";

function Home() {
  return (
    <Template>

      <Flex flexDirection="column">

        <Heading size="lg">Página Inicial</Heading>

        <Flex>
          <Flex flexDirection="column" style={{minWidth: '600px'}}>
            <h1 style={{ margin: '15px 0', fontSize: '1.5rem', color: '#333'}}>{informationModals.REGULAMENTO.title}</h1>

            <p>{informationModals.REGULAMENTO.content}</p>

          </Flex>
          <Flex flexDirection="column" style={{minWidth: '600px'}}>
            <h1 style={{ margin: '15px 0', fontSize: '1.5rem', color: '#333'}}>{informationModals.COMO_GANHAR.title}</h1>

            <p>{informationModals.COMO_GANHAR.content}</p>

          </Flex>
        </Flex>

      </Flex>

    </Template>
  );
}

export default Home;
