import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
  password: yup
    .string()
    .min(6, 'Campo necessita de pelo menos 6 caracteres')
    .required('Campo obrigatório'),
  passwordConfirmation: yup
    .string()
    .min(6, 'Campo necessita de pelo menos 6 caracteres')
    .required('Campo obrigatório')
    .oneOf([yup.ref('password'), null], 'Campos não correspondem'),
});

export const formOptions = {
  resolver: yupResolver(schema),
  mode: 'onChange',
  defaultValues: { password: '', passwordConfirmation: '' },
  reValidateMode: 'onChange',
};
