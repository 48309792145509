import PropTypes from 'prop-types';
import { Redirect, Route as RouterDom } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';

const Route = ({ isPrivate, component: Component, ...rest }) => {
  const { uniqueId } = useAuth();

  const shouldRedirect = !uniqueId && isPrivate;

  // eslint-disable-next-line react/prop-types
  const CheckRedirect = ({ location }) =>
    shouldRedirect ? (
      <Redirect to={{ pathname: '/', state: { from: location } }} />
    ) : (
      <Component />
    );

  return (
    <RouterDom
      {...rest}
      render={({ location }) => {
        return <CheckRedirect location={location} />;
      }}
    />
  );
};

Route.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.elementType.isRequired,
};

Route.defaultProps = {
  isPrivate: false,
};

export default Route;
