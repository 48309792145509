/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { 
  Accordion, 
  AccordionItem, 
  AccordionButton, 
  AccordionIcon, 
  AccordionPanel, 
  Box,
  Flex,
  Center,
  CircularProgress, 
  CircularProgressLabel,
  Icon,
  List, 
  ListItem,
  ListIcon,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { MdOutlineCircle, MdCheckCircle } from 'react-icons/md'
import uuid from 'react-uuid'

import { PercentFixed } from '../../../../helpers/percent'

function AccordionList({ props }) {
  const msl = props
  let achieved = 0;
  let colorList = '';
  const [accordionInfo, setAccordionInfo] = useState({
    percent: 0,
    color: '',
    itens: 0,
    achieved: 0,
    colorList: '',
    achievedList: ''
  })
  
  useEffect(() => {
    let auxItens = 0;
    let auxAchieved = 0;
    
    msl.products.map((el) => el.itens.filter((e) => {
      auxItens++
      if(e.achieved){
        auxAchieved++
      }
    }))
    
    setAccordionInfo({
      ...accordionInfo,
      itens: auxItens,
      achieved: auxAchieved,
      percent: PercentFixed(auxAchieved, auxItens),
      color: PercentFixed(auxAchieved, auxItens) >= msl.mark ? 'blue.500' : 'red.500'
    })
  }, [])

  return ( 
    <Tooltip label={`Meta de MSL ${msl.title} é de ${msl.mark}%`} borderRadius="8px" padding="2px 10px" placement="top-end">
      <Box className="card" h="auto" color='#666677'>
        <Flex justifyContent="space-between" pb={4}>
          <Center flexDir="column" alignItems="flex-start">
            <Box className="sub-title">{`MSL ${msl.title}`}</Box>
            <Text whiteSpace="nowrap" color={accordionInfo.color}>
              {`${accordionInfo.achieved} de ${accordionInfo.itens} itens`} 
              <Text as="span" fontSize="sm" color="grey.200">{` - Meta ${msl.mark}%`}</Text>
            </Text>
          </Center>
          <Center>
            <CircularProgress value={accordionInfo.percent} thickness="4px" color={accordionInfo.color} size="80px">
              <CircularProgressLabel color={accordionInfo.color}>{`${accordionInfo.percent}%`}</CircularProgressLabel>
            </CircularProgress>
          </Center>
        </Flex>
        <Accordion allowToggle>
          {msl.products.map((el) => {
            achieved = 0;
            el.itens.filter((e) => e.achieved ? achieved++ : '')
            colorList = PercentFixed(achieved, el.itens.length) >= msl.mark ? 'blue.500' : 'red.500';
            
            return (
              <AccordionItem key={uuid()} borderBottom="0">
                <AccordionButton pr="0" pl="0">
                  <Flex flex="1" textAlign="left" alignItems="center">
                    <Icon 
                      as={MdOutlineCircle} 
                      w={2} 
                      h={2} 
                      mr={2} 
                      mt={2} 
                      alignSelf="flex-start" 
                      color={colorList} 
                    />
                    <Box>
                      <Text>
                        {el.category}
                      </Text>
                      <Text fontSize="sm" color={colorList}>
                        {`${achieved} de ${el.itens.length} Itens`}
                      </Text>
                    </Box>
                  </Flex>
                  <Text as="span" color={colorList}>
                    {`${PercentFixed(achieved, el.itens.length)}%`}
                  </Text>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <List>
                    {el.itens.map((element) => {
                      return (
                        <ListItem key={uuid()} color={element.achieved?'':'red.500'}>
                          {element.achieved ? (
                            <ListIcon as={MdCheckCircle} w={3} h={3} mb={1} color="whatsapp.500" />
                          ):('')}
                          {element.name}
                        </ListItem>
                      )
                    })}
                  </List>
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </Box>
    </Tooltip>
  );
}

AccordionList.proptype = {
  props: PropTypes.shape({
    mark: PropTypes.number,
    title: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.shape({
      category: PropTypes.string,
      itens: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        achieved: PropTypes.bool
      }))
    }))
  }).isRequired
}

export default AccordionList;