import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Flex,
  Image,
  Box,
  Stack,
  Text,
  Heading,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton, useDisclosure,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import {useRef, useState} from "react";
import { useAuth } from '../../../hooks/useAuth';

export const informationModals = {
  "REGULAMENTO": {
    title: "Regulamento",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi aperiam beatae cumque debitis dolorem doloribus dolorum ea esse est explicabo, ipsam minus natus numquam placeat quasi qui quisquam sapiente sit soluta ullam ut vero voluptates. Earum, eveniet laborum!\n Animi asperiores cupiditate delectus error harum hic, nisi praesentium quos suscipit vero? Ab aliquam asperiores beatae culpa deleniti dolorem laudantium minus, placeat quas, quia quis ratione sint suscipit temporibus, voluptatum. \n Ab ad beatae cum cupiditate debitis, distinctio ducimus eaque enim, esse incidunt ipsum laborum laudantium maxime minus molestias natus neque officia quia quod ratione repellendus soluta voluptas voluptates voluptatibus voluptatum.\n Ab accusantium aspernatur assumenda aut consequuntur debitis ducimus, eos et id incidunt iusto natus necessitatibus non, officia quaerat quia ratione, rem repellendus sequi similique tempore ullam ut! Doloribus illo, nam.",
  },
  "COMO_GANHAR": {
    title: "Como Ganhar",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi aperiam beatae cumque debitis dolorem doloribus dolorum ea esse est explicabo, ipsam minus natus numquam placeat quasi qui quisquam sapiente sit soluta ullam ut vero voluptates. Earum, eveniet laborum!\n Animi asperiores cupiditate delectus error harum hic, nisi praesentium quos suscipit vero? Ab aliquam asperiores beatae culpa deleniti dolorem laudantium minus, placeat quas, quia quis ratione sint suscipit temporibus, voluptatum. \n Ab ad beatae cum cupiditate debitis, distinctio ducimus eaque enim, esse incidunt ipsum laborum laudantium maxime minus molestias natus neque officia quia quod ratione repellendus soluta voluptas voluptates voluptatibus voluptatum.\n Ab accusantium aspernatur assumenda aut consequuntur debitis ducimus, eos et id incidunt iusto natus necessitatibus non, officia quaerat quia ratione, rem repellendus sequi similique tempore ullam ut! Doloribus illo, nam.",
  }
}
const LoggedStep = () => {
  const history = useHistory();
  const { signOut, name } = useAuth();

  const navigateToHome = () => {
    history.push('/performance');
  };

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  const [modalData, setModalData] = useState({})

  const handleModal = (modal) => {
    console.log(informationModals[modal])

    setModalData(informationModals[modal])
    onOpen()
  }


  return (
    <>
      <Flex mb={8} width="450px" margin="0 auto" flexDir="column">
        <Image
          margin="0 auto"
          width="162px"
          objectFit="cover"
          src="images/successfully-signed.svg"
          alt=""
        />
        <Heading
          as="h2"
          mb={2}
          mt={10}
          textAlign="center"
          fontSize="2xl"
          color="brand-main"
        >
          {`Bem vindo ${name}!`}
        </Heading>
        <Text fontSize="md" mb={4} textAlign="center" color="brand-brighter">
          Confira abaixo mais informações sobre o programa
        </Text>
        <Box paddingX={4}>
          <Stack
            direction={{
              base: 'column',
              md: 'row',
            }}
            mb={{
              base: 8,
              md: 4,
            }}
            spacing={{
              base: 4,
              md: 8,
            }}
          >
            <Button fontWeight="400" width="100%" variant="brand-brighter" onClick={() => handleModal("REGULAMENTO")}>
              Regulamento
            </Button>
            <Button fontWeight="400" width="100%" variant="brand-brighter" onClick={() => handleModal("COMO_GANHAR")}>
              Como Ganhar
            </Button>
          </Stack>
          <Button
            fontWeight="400"
            onClick={navigateToHome}
            width="100%"
            variant="brand-brighter"
          >
            Acompanhar performance
          </Button>
          <Flex
            as="button"
            alignItems="center"
            onClick={signOut}
            fontSize="md"
            _hover={{ textDecoration: 'underline' }}
            marginY={8}
          >
            <ArrowBackIcon />
            <Text>Sair</Text>
          </Flex>

          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                  { modalData.title }
                </AlertDialogHeader>

                <AlertDialogBody>
                  { modalData.content }
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Fechar
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </Box>
      </Flex>
    </>
  );
};

export default LoggedStep;
