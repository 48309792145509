/* eslint-disable */
import {Text, Grid, GridItem, useMediaQuery, Icon, Button} from '@chakra-ui/react';
import { MdOutlineInfo, MdVpnKey } from 'react-icons/md'
import PropTypes from 'prop-types';

import Table from './Table';
import AccordionList from './AccordionList';
import Result from './Result';
import ModalPerformanceSubordinados from "../../../pages/Performance/ModalPerformanceSubordinados";
import {useEffect, useState} from "react";
import api from "../../../services/api";

const formatData = (data) => {
  return new Date(data).toLocaleDateString('pt-BR', {
    month: 'long',
    day: 'numeric',
  });
}

function Steps({props, infoParticipant, kpi, active, activeStep, index}) {
  const params = props;
  const [isMobile] = useMediaQuery("(max-width: 994px)")
  const [isOpen, setIsOpen] = useState(false)
  const [performance, setPerformance] = useState([]);
  const [loading, setLoading] = useState(false);

  const dash = {
    sell: {
      in: {
        value: 4980189.01,
        mark: 4250000,
      },
      out: {
        value: 2567900.99,
        mark: 3900000,
      },
    },
    msl: [
      {
        title: 'Checkout',
        mark: 85,
        products: [
          {
            category: 'Chocolates',
            itens: [{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},]
          },
          {
            category: 'Drops',
            itens: [{name: 'item', achieved: true},{name: 'item', achieved: false},{name: 'item', achieved: false},]
          },
          {
            category: 'Gomas',
            itens: [{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: false},{name: 'item', achieved: false},{name: 'item', achieved: false},{name: 'item', achieved: false},]
          }],
      },
      {
        title: 'Gôndola',
        mark: 90,
        products: [
          {
            category: 'Bebidas em Pó',
            itens: [{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},]
          },
          {
            category: 'Biscoitos Salgados',
            itens: [{name: 'item', achieved: true},{name: 'item', achieved: false},{name: 'item', achieved: true},{name: 'item', achieved: true},]
          },
          {
            category: 'Biscoitos Doces',
            itens: [{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},]
          },
          {
            category: 'Chocolates',
            itens: [{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},]
          },
          {
            category: 'Fermentos e Sobremesas',
            itens: [{name: 'item', achieved: true},{name: 'item', achieved: true},{name: 'item', achieved: true},]
          }],
      }
    ]
  };

  const handleParticipantSubordinados = async () => {
    if (!active) return null;

    setLoading(true);
    console.log("local Storage", localStorage.getItem('token'));

    api.get(`/admin/results/prize/participants?kpi_id=${kpi[0]?.id}&participant_id=${infoParticipant?.participant?.id}`,)
      .then((response) => {
        setPerformance(response.data.positions);
      })
      .catch((error) => {
        console.log("Error", error)
      }).finally(() => {
      setLoading(false);
    });

  }

  useEffect(() => {
    handleParticipantSubordinados();

    return () => {
      setPerformance([]);
    }
  }, []);

  console.log("Props KPI", props)

  return (
    <>
      <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "1rem"}}>
        <Text fontWeight="bold" pb={2}>
          {`${params.name} - `}
          <Text as="small" fontWeight="normal" color="grey.200">
            {`${ formatData(props.start_date)} a ${formatData(props.end_date)}`}
          </Text>
        </Text>
        <Button onClick={() => setIsOpen(true)}>Acompanhar desempenho dos Subordinados</Button>
      </div>



      <Grid w="full" h="full" gridTemplateColumns={isMobile ? "1fr" : "65% 1fr"} gap={4}>
        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={4}
        >
          <GridItem colSpan={2} marginBottom={4}>
            <Table props={dash} participant={infoParticipant} campaignStep={props}/>
            <Text as="small" color="gray.dark" ml={6}>
              <Icon as={MdVpnKey} mr={2} color="gray.200" />
              Meta de Sell-In é
              {' '}
              <Text as="u">chave de entrada</Text>
              {' '}
              na campanha
            </Text>
          </GridItem>
          {/*<GridItem>*/}
          {/*  <AccordionList props={dash.msl[1]} />*/}
          {/*</GridItem>*/}
        </Grid>
        <GridItem h={isMobile ? "100%" : "60%"} maxH="30rem">
          { kpi.map((item, index) => {
            if (item?.id_participant_role !== infoParticipant?.participant?.id_participant_role) return null;
            return (
              <Result props={params} infoParticipant={infoParticipant} kpiEquation={item.kpiEquation} kpi={item} />
            )
          })}

          <Text as="small" color="gray.dark" ml={6}>
            <Icon as={MdOutlineInfo} mr={1} mb={1} color="gray.400" />
            Estes são os valores parciais de premiação oficiais
          </Text>
        </GridItem>
      </Grid>

      <ModalPerformanceSubordinados visible={isOpen} setVisible={() => setIsOpen(false)} data={performance} kpi={kpi}/>
    </>
  );
}

Steps.propTypes = {
  props: PropTypes.shape({
    name: PropTypes.string,
    info: PropTypes.bool,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    active: PropTypes.bool,
    activeStep: PropTypes.number,
    index: PropTypes.number,
  }).isRequired,
};

export default Steps;
