/* eslint-disable */
/* eslint-disable react/jsx-curly-newline */
import { useState } from 'react';
import { Flex, Box, Center, Image, Grid } from '@chakra-ui/react';
import { NavLink, Link } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import Subnav from '../Subnav';

function Sidenav({ props }) {
  const info = props;
  const initialValues = {
    width: 14,
    display: 'none',
    rows: '1fr',
    showSub: false,
  };

  const [toggle, setToggle] = useState(initialValues);

  function openMenu() {
    setToggle({
      ...toggle,
      width: 48,
      display: 'flex',
      rows: '1fr 70%',
    });
  }

  function closeMenu() {
    setToggle({
      ...toggle,
      width: 14,
      display: 'none',
      rows: '1fr',
      showSub: false,
    });
  }
  return (
    <Box
      as="nav"
      position="fixed"
      zIndex="2000"
      bottom="0"
      bg="linear-gradient(.31deg, #212121 0%, #3b3b3b 100%)"
      height={{ base: 14, md: '100vh' }}
      width={{
        base: '100vw',
        md: toggle.width,
      }}
      onMouseLeave={closeMenu}
    >
      <Flex direction="column" height="100%">
        <Center height={14} borderBottom="1px solid #666">
          <Link as={NavLink} to="/home">
            <Image
              height={8}
              width={8}
              objectFit="cover"
              src="/images/pat-logo-accent.svg"
              alt="Logo"
            />
          </Link>
        </Center>
        {info.map(item => {
          return (
            <Grid
              key={uuid()}
              templateColumns={toggle.rows}
              _hover={{
                backgroundColor: '#272727',
              }}
            >
              <Center
                borderRadius="xl"
                margin=".5rem"
                height={10}
                stroke="#9a9a9a"
                bg="transparent"
                _hover={{
                  bg: 'brand-highlight',
                  cursor: 'pointer',
                  stroke: 'black',
                }}
                pos="relative"
                zIndex="2"
                onMouseOver={openMenu}
              >
                {item.icon}
              </Center>
              <Center
                display={toggle.display}
                justifyContent="flex-start"
                height={10}
                margin=".5rem"
                color="#9a9a9a"
                fontSize="14px"
                cursor="pointer"
                onMouseLeave={openMenu}
              >
                {item.sub.length ? (
                  <>
                    {item.title}
                    <ChevronRightIcon
                      position="absolute"
                      right="5%"
                      onMouseOver={() =>
                        setToggle({
                          ...toggle,
                          showSub: true,
                        })
                      }
                    />
                    {toggle.showSub && <Subnav props={item} />}
                  </>
                ) : (
                  <>
                    <Link as={NavLink} to={item.href}>
                      {item.title}
                    </Link>
                  </>
                )}
              </Center>
            </Grid>
          );
        })}
      </Flex>
    </Box>
  );
}

Sidenav.propTypes = {
  props: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      title: PropTypes.string,
      sub: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          href: PropTypes.string,
        }),
      ),
      href: PropTypes.string,
    }),
  ).isRequired,
};

export default Sidenav;
