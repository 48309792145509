/* eslint-disable react/prop-types */
import { useRouteMatch } from 'react-router-dom';
import { Flex, Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import Topbar from '../Topbar';
import Sidenav from '../Sidenav';
import { HomeIcon, PeopleIcon, SheetIcon, BarGraphIcon } from '../Icons';

function Template({ children }) {
  const {path} = useRouteMatch();
  const info = [
    {
      icon: <HomeIcon />,
      title: 'Página Inicial',
      sub: [],
      href: '/home',
    },
    // {
    //   icon: <PeopleIcon />,
    //   title: 'Participantes',
    //   sub: [
    //     {
    //       title: 'Gestão de Participantes',
    //       href: '/participants/management',
    //     },
    //     {
    //       title: 'Importar Participantes',
    //       href: '',
    //     },
    //     {
    //       title: 'Relatório de Metas',
    //       href: '',
    //     },
    //   ],
    //   href: 'participants',
    // },
    {
      icon: <SheetIcon />,
      title: 'Relatório',
      sub: [],
      href: 'report',
    },
    {
      icon: <BarGraphIcon />,
      title: 'Perfomance',
      sub: [],
      href: '/performance',
    },
  ];
  return (
    <Box minHeight="100vh" height="100%" maxWidth="100vw">
      <Flex
        bg="grey.50"
        minHeight="100vh"
        height="100%"
        maxWidth="100%"
        margin="auto"
        paddingLeft={{
          base: 0,
          md: 14,
        }}
        paddingTop={14}
        paddingBottom={{
          base: 14,
          md: 0,
        }}
      >
        <Box
          bg="white"
          border="1px solid #f0f0f9"
          borderRadius="10px"
          margin=".5em"
          width="100%"
        >
          {children}
        </Box>
      </Flex>
      <Topbar props={{info, path}} />
      <Sidenav props={info} />
    </Box>
  );
}

Template.prototype = {
  children: PropTypes.node.isRequired,
};

export default Template;
