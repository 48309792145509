import ProptTypes from 'prop-types';
import React, {
  useEffect,
  useCallback,
  createContext,
  useState,
  useContext,
} from 'react';

import { useAuth } from './useAuth';

const LoginStepsContext = createContext();

const INITIAL_STATE = {
  uniqueId: '',
  name: '',
  currentStep: 'STEP_ONE',
};

export const LoginStepProvider = ({ children }) => {
  const { uniqueId } = useAuth();
  const [loginState, setState] = useState(() => {
    return INITIAL_STATE;
  });

  useEffect(() => {
    if (uniqueId) {
      setState({ ...INITIAL_STATE, currentStep: 'LOGGED_STEP' });
    }
  }, [uniqueId, setState]);

  const setLoginState = useCallback(
    newState => {
      return setState(currentState => {
        return { ...currentState, ...newState };
      });
    },
    [setState],
  );

  console.log(loginState)

  return (
    <LoginStepsContext.Provider value={{ loginState, setLoginState }}>
      {children}
    </LoginStepsContext.Provider>
  );
};

export const useLoginStepContext = () => {
  const context = useContext(LoginStepsContext);

  if (!context) {
    throw new Error('useMyContext must be used within a MyContextProvider');
  }

  return context;
};

LoginStepProvider.propTypes = {
  children: ProptTypes.node.isRequired,
};

export const STEP_ONE = 'STEP_ONE';
export const STEP_TWO = 'STEP_TWO';
export const STEP_THREE = 'STEP_THREE';
export const LOGIN_STEP = 'LOGIN_STEP';
export const LOGGED_STEP = 'LOGGED_STEP';
