import { useEffect } from 'react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';

import { useLoginStepContext } from '../../../hooks/useLoginSteps';
import MaskedInput from '../../shared/MaskedInput';
import { useAuth } from '../../../hooks/useAuth';

const LoginStep = () => {
  const { signIn } = useAuth();
  const { loginState } = useLoginStepContext();
  const { handleSubmit, control, errors, setValue } = useForm({
    defaultValues: { uniqueId: '', password: '' },
  });

  useEffect(() => {
    setValue('uniqueId', loginState.uniqueId);
  }, [loginState, setValue]);

  async function onSubmit(data) {
    const { password } = data;
    await signIn({ username: loginState.uniqueId, password });
  }

  return (
    <>
      <Box mb={16}>
        <Text as="small" fontSize="xl" color="brand-main">
          Seja bem-vindo ao programa
        </Text>
        <Heading
          as="h1"
          lineHeight="normal"
          fontSize={{ base: '4xl', md: '5xl' }}
          color="brand-main"
        >
          Campanha de Incentivo
        </Heading>
      </Box>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <FormControl id="unique_id" mb="32px" isInvalid={errors.uniqueId}>
          <FormLabel fontWeight="bold" color="grey.200">
            Login
          </FormLabel>
          <MaskedInput
            control={control}
            name="uniqueId"
            isDisabled
            variant="filled"
            // mask="99.999.999/9999-99"
            type="text"
          />
          <FormErrorMessage>{errors.uniqueId?.message}</FormErrorMessage>
        </FormControl>
        <FormControl id="unique_id" mb="32px" isInvalid={errors.password}>
          <FormLabel fontWeight="bold" color="grey.200">
            Senha
          </FormLabel>
          <MaskedInput control={control} name="password" type="password" />
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>
        <Flex justify="flex-end">
          <Button
            size="lg"
            width="100%"
            type="submit"
            variant="brand-brighter"
            rightIcon={<ArrowForwardIcon />}
          >
            <Text fontSize="md">Continuar</Text>
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default LoginStep;
