export const isValidDate = date => {
  // eslint-disable-next-line no-restricted-globals
  return date instanceof Date && !isNaN(date);
};

export const ddMMyyyyToDate = dateString => {
  const [day, month, year] = dateString.split('/');

  const dateObject = new Date(+year, +month - 1, +day);

  if (!isValidDate) {
    return null;
  }

  return dateObject;
};
