import React, { useCallback, createContext, useState, useContext } from 'react';
import { useToast } from '@chakra-ui/react';
import ProptTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { login } from '../services/endpoints/patEndpoints';
import api from '../services/api';

const AuthContext = createContext();

export const AuthProviver = ({ children }) => {
  const history = useHistory();
  const toast = useToast();
  const [participant, setParticipant] = useState();

  const [authState, setAuthState] = useState(() => {
    const token = sessionStorage.getItem('@PAT:token');
    const user = sessionStorage.getItem('@PAT:username');
    // eslint-disable-next-line no-shadow
    const participant = sessionStorage.getItem('@PAT:user');

    if (token && user) {
      return { token, user: JSON.parse(user), participant: JSON.parse(participant)};
    }

    return {};
  });

  const signIn = async ({ username, password }) => {
    try {
      const { data: user } = await api.post(login, {
        username,
        password,
      });
      if (user.username && user.token) {
        sessionStorage.setItem('@PAT:token', user.token);
        sessionStorage.setItem('@PAT:id', user.id);
        sessionStorage.setItem('@PAT:username', JSON.stringify(user));
        sessionStorage.setItem('@PAT:user', JSON.stringify(user?.participant));

        api.defaults.headers.authorization = `Bearer ${user.token}`;

        setParticipant(user?.participant)
        return setAuthState({ token: user.token, user, participant: user?.participant, id: user.id});
      }
      return false;
    } catch (e) {
      return toast({
        position: 'top-right',
        title: 'Erro de conexão.',
        description: 'Por favor, tente novamente.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const signOut = useCallback(() => {
    sessionStorage.removeItem('@PAT:token');
    sessionStorage.removeItem('@PAT:username');
    sessionStorage.removeItem('@PAT:user');
    sessionStorage.removeItem('@PAT:id');

    api.defaults.headers.authorization = '';
    setAuthState({});
    history.push('/');
  }, [history]);

  return (
    <AuthContext.Provider
      value={{
        token: authState?.token,
        uniqueId: authState?.user?.username,
        name: authState?.user?.name,
        participant: authState?.participant,
        id: authState?.id,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useMyContext must be used within a MyContextProvider');
  }

  return context;
};

AuthProviver.propTypes = {
  children: ProptTypes.node.isRequired,
};
