/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Box, Flex, Menu, MenuButton, MenuList, MenuItem, Center } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, ChevronRightIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types'
import {Link, NavLink} from "react-router-dom";
import ProfileIcon from '../Icons/Profile'
import {useAuth} from "../../../hooks/useAuth";


function Topbar({ props }) {
  const params = props
  const [namePage, setNamePage] = useState('')

  useEffect(() => {
    params.info.map((el) => {
      if(el.sub.length){
        return el.sub.map((e) => e.href === params.path ? setNamePage(e.title) : null)
      }
        return el.href === params.path ? setNamePage(el.title) : null
    })
  }, [])

  const { uniqueId, name, signOut, participant } = useAuth()

  console.log(uniqueId, name)

  return (
    <Box
      as="header"
      position="absolute"
      top="0"
      bg="#fff"
      boxShadow="0px 1px 0px rgba(51, 51, 68, 0.07)"
      height={14}
      maxWidth="100vw"
      width="100%"
      paddingLeft={{base:0, md: 14}}
      zIndex="2000"
    >
      <Flex width="100%" alignItems="center" justifyContent="space-between" pl={8}>
        <Box fontWeight="bold" width="100%">
          Loja Perfeita
          <ChevronRightIcon color="#ddddee" margin="0 1.5% 0 1.5%" />
          {namePage}
        </Box>
        <Menu height="100%" width={18}>
          {({ isOpen }) => (
            <>
              <MenuButton
                bg="transparent"
                height={14}
                width={isOpen ? 56 : 14}
                borderLeft="1px solid #F0F0F9"
              >
                <Center justifyContent={isOpen ? 'space-between' : 'center'}>
                  <ProfileIcon color="#3b3b3b" />
                  {isOpen ? (
                    <>
                      { name }
                      <ChevronUpIcon color="grey.600" />
                    </>
                  ) : (
                    <>
                      <ChevronDownIcon color="grey.600" />
                    </>
                  )}
                </Center>
              </MenuButton>
              <MenuList>
                <MenuItem>
                  <Link as={NavLink} to="/profile">
                    Meu Perfíl
                  </Link>
                </MenuItem>
                <MenuItem borderTop="1px solid #f0f0f9" onClick={signOut}>Fazer Logout</MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </Flex>
    </Box>
  );
}

Topbar.propTypes = {
  props: PropTypes.shape({
    info: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.element,
        title: PropTypes.string,
        sub: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            href: PropTypes.string,
          }),
        ),
        href: PropTypes.string,
      })),
    path: PropTypes.string
  }
  ).isRequired
};

export default Topbar;
