import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import Login from '../pages/Login';
import Home from '../pages/Home';
import Performance from '../pages/Performance';
import Management from '../pages/Participants/Management';

import Route from './Route';
import Profile from "../pages/Profile";

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/home" component={Home} isPrivate />
      <Route path="/performance" component={Performance} isPrivate />
      <Route path="/participants/management" component={Management} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>
);

export default Routes;
