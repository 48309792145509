/* eslint-disable */
import Chart from 'react-google-charts'
import PropTypes from 'prop-types';
import {Flex, Center, Box, Progress, Tooltip, Icon, Text} from '@chakra-ui/react';
import {MdVpnKey} from 'react-icons/md'
import {Currency} from '../../../../helpers/currency';

function Table({props, participant, campaignStep}) {
	const dash = props;
	console.log("TABLE", participant, campaignStep);

	const sellIn = campaignStep.variables?.find((item) => item.slug === 'sell_in') || 1;
	const metaSellIn = campaignStep.variables?.find((item) => item.slug === 'meta_sell_in') || 1;
	const sellOut = campaignStep.variables?.find((item) => item.slug === 'sell_out') || 1;
	const metaSellOut = campaignStep.variables?.find((item) => item.slug === 'meta_sell_out') || 1;

	return (
		<Tooltip label="Meta de Sell-In é chave de entrada na campanha" borderRadius="8px" padding="2px 10px" placement="top-end">
			<Flex className="card" padding="0">
				<Flex w="30%" borderRight="1px solid #eee" padding={4} flexDir="column">
          {
            campaignStep?.variables?.filter(variable => {
              return variable?.unique_id == participant?.participant?.unique_id
            })?.map(variable => (
              <Center h="50%" alignItems="flex-start" flexDir="column">
                <Box whiteSpace="nowrap" w="full" color="#666677" fontWeight="600">
                  { variable?.real_name}
                  {' '}
                  <Icon as={MdVpnKey} color="gray.200"/>
                </Box>
                <Text my="5px">
                  <Text as="span" fontSize="30px">{variable.data_type === "number" ? Currency(variable.value).slice(3) : variable.value }</Text>
                </Text>

                  <Flex
                    flexDir="column"
                    w="full"
                    padding="3px 10px"
                    borderRadius="10px"
                    bg={Number(variable.value) >= Number(variable.value) ? "rgba(74,71,179,.11)" : "rgba(255,127,91,.11)"}
                  >
                    <Center
                      w="full"
                      fontWeight="700"
                      color="#666677"
                      justifyContent="space-between"
                    >
                      {/*<Box>Meta:</Box>*/}
                      {/*<Box>*/}
                      {/*  {Currency(variable.value)}*/}
                      {/*</Box>*/}
                    </Center>
                    <Box d="inline-flex" w="full" color={Number(sellIn.value) >= Number(variable.value) ? "blue.500" : "red.500"}>
                      {/*{((variable.value / variable.value) * 100).toFixed(0)}*/}
                      {/*%*/}
                      <Progress
                        value={100}
                        w="100%"
                        h="3px"
                        ml="auto"
                        mt={3}
                        colorScheme={Number(variable.value) >= Number(variable.value) ? "blue" : "orange"}
                        bg={Number(variable.value) >= Number(variable.value) ? "" : "#f59e86"}
                      />
                    </Box>
                  </Flex>

              </Center>
            ))
          }
					{/*<Center h="50%" alignItems="flex-start" flexDir="column">*/}
					{/*	<Box whiteSpace="nowrap" color="#666677" fontWeight="600">*/}
					{/*		Sell-Out Acumulado*/}
					{/*	</Box>*/}
					{/*	<Text my="5px">*/}
					{/*		<Text as="sup" fontSize="14px" mr=".2rem" top="-.65rem">{Currency(sellOut.value).slice(0, 2)}</Text>*/}
					{/*		<Text as="span" fontSize="30px">{Currency(sellOut.value).slice(3)}</Text>*/}
					{/*	</Text>*/}
					{/*	<Flex*/}
					{/*		flexDir="column"*/}
					{/*		w="full"*/}
					{/*		padding="3px 10px"*/}
					{/*		borderRadius="10px"*/}
					{/*		bg={Number(sellOut.value) >= Number(metaSellOut.value) ? "rgba(74,71,179,.11)" : "rgba(255,127,91,.11)"}*/}
					{/*	>*/}
					{/*		<Center*/}
					{/*			w="full"*/}
					{/*			fontWeight="700"*/}
					{/*			color="#666677"*/}
					{/*			justifyContent="space-between"*/}
					{/*		>*/}
					{/*			<Box>Meta:</Box>*/}
					{/*			<Box>*/}
					{/*				{Currency(metaSellOut.value)}*/}
					{/*			</Box>*/}
					{/*		</Center>*/}
					{/*		<Box d="inline-flex" w="full" color={Number(sellOut.value) >= Number(metaSellOut.value) ? "blue.500" : "red.500"}>*/}
					{/*			{((sellOut.value / metaSellOut.value) * 100).toFixed(0)}*/}
					{/*			%*/}
					{/*			<Progress*/}
					{/*				value={(sellOut.value / metaSellOut.value) * 100}*/}
					{/*				w="70%"*/}
					{/*				h="3px"*/}
					{/*				ml="auto"*/}
					{/*				mt={3}*/}
					{/*				colorScheme={Number(sellOut.value) >= Number(metaSellOut.value) ? "blue" : "orange"}*/}
					{/*				bg={Number(sellOut.value) >= Number(metaSellOut.value) ? "" : "#f59e86"}*/}
					{/*			/>*/}
					{/*		</Box>*/}
					{/*	</Flex>*/}
					{/*</Center>*/}
				</Flex>
				<Box w="70%" padding={4}>
					{/*<Chart*/}
					{/*	maxHeight="500px"*/}
					{/*	chartType="LineChart"*/}
					{/*	loader={<div>Carregando...</div>}*/}
					{/*	data={[*/}
					{/*		['x', 'Sell-In', 'Sell-Out'],*/}
					{/*		[0, 0, 0],*/}
					{/*		[1, 10, 5],*/}
					{/*		[2, 23, 15],*/}
					{/*		[3, 17, 9],*/}
					{/*		[4, 18, 10],*/}
					{/*		[5, 9, 5],*/}
					{/*		[6, 11, 3],*/}
					{/*		[7, 27, 19],*/}
					{/*	]}*/}
					{/*	options={{*/}
					{/*		title: 'Evolução Parcial',*/}

					{/*		series: {*/}
					{/*			1: {curveType: 'function'},*/}
					{/*		},*/}
					{/*	}}*/}
					{/*	rootProps={{'data-testid': '2'}}*/}
					{/*/>*/}
				</Box>
			</Flex>
		</Tooltip>
	);
}

Table.propTypes = {
	props: PropTypes.shape({
		sell: PropTypes.objectOf(PropTypes.object),
	}).isRequired,
	participant: PropTypes.shape({
		participant: PropTypes.objectOf(PropTypes.object),
		variables: PropTypes.objectOf(PropTypes.object),
	}).isRequired,
	campaignStep: PropTypes.object,
};

export default Table;
