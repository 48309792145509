import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { Controller } from 'react-hook-form';
import { Input } from '@chakra-ui/react';

const MaskedInput = ({
  isDisabled,
  control,
  name,
  mask,
  type,
  variant,
  placeholder,
}) => (
  <Controller
    control={control}
    name={name}
    render={({ onChange, value }) => (
      <Input
        as={InputMask}
        maskPlaceholder={null}
        type={type}
        mask={mask}
        variant={variant}
        placeholder={placeholder}
        onChange={({ target }) => onChange(target.value)}
        value={value}
        isDisabled={isDisabled}
        size="md"
        borderColor="#ddddee"
        _focus={{ borderColor: 'brand-main', borderWidth: '2px' }}
      />
    )}
  />
);

MaskedInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  mask: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
};

MaskedInput.defaultProps = {
  isDisabled: false,
  placeholder: '',
  variant: undefined,
};

export default MaskedInput;
