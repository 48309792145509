import {useState} from "react";
import {Heading, Container, FormControl, FormLabel, Input, Divider, Center, Button, Flex, useToast} from '@chakra-ui/react'

import Template from "../../components/shared/Template";
import {useAuth} from "../../hooks/useAuth";
import api from "../../services/api";
import {updateProfile} from "../../services/endpoints/patEndpoints";


const Profile = () => {

  const toast = useToast();
  const {name, token, participant} = useAuth();
  const [data, setData] = useState({
    id: participant?.unique_id,
    name,
    email: participant?.participantManager?.email,
    senha: "",
    confirme_senha: "",
  });
  const [error, setError] = useState('')

  const handleUpdateData = (e) => {
    // eslint-disable-next-line no-shadow
    const {name, value} = e.target;

    setData(prev => ({
      ...prev,
      [name]: value
    }))

  }

  const handleSubmit = async () => {
    if (data?.senha.length > 0) {
      if (data?.senha.length < 6) {
        setError('A senha deve ter no mínimo 6 caracteres!')
        return;
      }

      if (data?.senha !== data?.confirme_senha) {
        setError('As senhas devem ser iguais!')
        return;
      }

      setError('')
    }

    const response = await api.post(updateProfile, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    console.log(response)

    if (response?.status === 200) {

      // eslint-disable-next-line consistent-return
      return toast({
        position: 'top-right',
        title: 'Sucesso!',
        description: 'Dados atualizados com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }

    console.log(participant, response)

  }

  return (
    <Template>
      <Container>
        <Flex flexDirection="column">
          <Center style={{
            marginTop: '2rem',
            marginBottom: '2rem',
          }}
          >
            <Heading size='2xl' noOfLines={2}>Meu Perfil</Heading>
          </Center>


          <FormControl>
            <FormLabel>Nome</FormLabel>
            <Input type="text" name="name" value={data?.name} onChange={handleUpdateData} disabled />
          </FormControl>

          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input type="email" name="email" value={data?.email} onChange={handleUpdateData} disabled />
          </FormControl>

          <Center height='50px'>
            <Divider orientation='vertical'/>
          </Center>

          <FormControl>
            <FormLabel>Senha</FormLabel>
            <Input type="password" name="senha" value={data?.senha} onChange={handleUpdateData}/>
          </FormControl>

          <FormControl>
            <FormLabel>Confirme a senha</FormLabel>
            <Input type="password" name="confirme_senha" value={data?.confirme_senha} onChange={handleUpdateData}/>
          </FormControl>

          <Center style={{
            marginTop: '2rem',
            marginBottom: '2rem',
          }}
          >
            <Flex flexDirection="column">
              {error && (
                <p style={{
                  color: 'red',
                  fontSize: '0.75rem',
                  display: 'block',
                  textAlign: 'center',
                  margin: "0.5rem 0",
                }}
                >
                  {error}
                </p>
              )}
              <Button onClick={handleSubmit}>
                Atualizar meus dados
              </Button>
            </Flex>
          </Center>


        </Flex>


      </Container>
    </Template>
  )
}

export default Profile
