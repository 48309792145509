import { Box } from '@chakra-ui/react';

const LoginSidebar = () => (
  <Box
    position={{
      base: 'absolute',
      md: 'fixed',
    }}
    h={{
      base: 44,
      md: '100vh',
    }}
    width={{
      base: '100vw',
      md: '50vw',
    }}
    bg="brand-main"
    maxW="100%"
    backgroundImage={`url("images/pat-logo-accent.svg")`}
    backgroundRepeat="no-repeat"
    backgroundSize="300px"
    backgroundPosition="center center"
  />
);

export default LoginSidebar;
